import React, { Component } from 'react'
import { graphql } from 'gatsby'
import classNames from 'classnames'
import { document, window } from 'browser-monads'
import { connect } from 'react-redux'
import { Row, Col } from 'reactstrap'
import exitIntent from 'exit-intent'
import Test from '../components/Test'
import Pagination from '../components/Pagination'
import Results from '../components/Results'
import PageHelmet from '../components/PageHelmet'
import jobTestPrepImage from '../images/job-test-prep.png'
import './style.css'
import BuyBox from '../components/BuyBox'
import StickyTestHeader from '../components/StickyTestHeader'
import ExitIntentPopup from '../components/ExitIntentPopup'
import decorateURLWithTID from '../utils/decorateUrlTid'
import { setHelloBarContent } from '../store/features/aptitudeTest/aptitudeTestSlice'

class AptitudeTest extends Component {
  state = {
    index: 0,
    answers: {},
    finished: false,
    questionIndex: 0,
    showResults: false,
    url: `https://www.wikijob.co.uk${this.props.location.pathname}`,
    stickyHeaderShown: false,
    exitIntentPopupShown: false,
    pageIsScrolled: false,
    initialOffset: 0,
  }

  test = this.props.data.datoCmsTest

  handleAnswer = (questionIndex, answerIndex) => {
    const index = questionIndex
    const answers = {
      ...this.state.answers,
      [questionIndex]: answerIndex,
    }
    this.setState({ index, answers, questionIndex })

    // TODO: Indicator for answered questions
    const countOfLabels = document
      .getElementsByClassName('questionContent')
      [questionIndex].getElementsByTagName('label')
    for (let index = 0; index < countOfLabels.length; index++) {
      const hasAnswer = countOfLabels
        .item(index)
        .classList.contains('answeredPoint')
      if (hasAnswer) {
        countOfLabels.item(index).classList.remove('answeredPoint')
      }
    }
    const answeredPoint = countOfLabels[answerIndex]
    answeredPoint.classList.add('answeredPoint')

    this.scrollToNextQuestion(index)
  }

  scrollToNextQuestion = (currentQuestionIndex) => {
    const card = document.getElementsByClassName(`questionContent`)[
      currentQuestionIndex + 1
    ]
    if (card !== undefined) {
      const cardOffsetTop = card.offsetTop
      if (window.innerWidth <= 767) {
        window.scrollTo({ top: cardOffsetTop + 70, behavior: 'smooth' })
      } else if (window.innerWidth >= 768 && window.innerWidth <= 991) {
        window.scrollTo({ top: cardOffsetTop + 150, behavior: 'smooth' })
      } else if (window.innerWidth >= 992) {
        window.scrollTo({ top: cardOffsetTop + 135, behavior: 'smooth' })
      }
    } else {
      return null
    }
  }

  handleNavigate = () => {
    this.setState({ showResults: true })
    setTimeout(() => {
      const practiceMore = document.getElementsByClassName(`result`)[0]
      practiceMore.scrollIntoView({ block: 'start', behavior: 'smooth' })
    }, 300)
  }

  listenToScroll = () => {
    const winScroll = window.pageYOffset
    if (!this.stickyHeaderShown && winScroll > 430) {
      this.setState({ stickyHeaderShown: true })
    } else {
      this.setState({ stickyHeaderShown: false })
    }
    if (Math.abs(winScroll - this.state.initialOffset) > 30) {
      if (!this.state.pageIsScrolled) {
        exitIntent({
          threshold: 18,
          maxDisplays: 1,
          eventThrottle: 500,
          onExitIntent: () => {
            this.setState({ ...this.state, exitIntentPopupShown: true })
          },
        })
        this.setState({ ...this.state, pageIsScrolled: true })
      }
    }
  }

  componentDidMount() {
    this.props.setHelloBarContent({
      url: this.test.url,
      text: `Don't miss out on that job. Practice today!`,
      buttonText:`Take a ${this.test.name} test!`,
    })
    window.addEventListener('scroll', this.listenToScroll, { passive: true })
    window.dataLayer && window.dataLayer.push({ event: 'componentDidMount' })
    setTimeout(this.setState({ initialOffset: window.pageYOffset }), 300)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenToScroll)
  }

  render() {
    const { pageIsScrolled, exitIntentPopupShown } = this.state

    return (
      <>
        <div
          className={classNames(
            {
              stickyTestHeaderWrapperIsShown: this.state.stickyHeaderShown,
            },
            'stickyTestHeaderWrapper',
          )}
        >
          <StickyTestHeader test={this.test} location={this.props.location} />
        </div>
        <PageHelmet
          {...{
            title: this.test.metaTitle || this.test.name,
            description: this.test.metaDescription && this.test.metaDescription,
            url: this.test.urlCanonical
              ? `https://www.wikijob.co.uk${this.test.urlCanonical}`
              : this.state.url,
          }}
        />
        <Row>
          <Col lg={8}>
            <style dangerouslySetInnerHTML={{ __html: styles }} />
            {this.test.introText && (
              <div className="card">
                <div className="card-body text-center">
                  <div dangerouslySetInnerHTML={{ __html: this.test.introText }} />
                  <a
                    {...{
                      href: decorateURLWithTID(
                        this.test.url,
                        this.props.location.pathname,
                        'jtplogo',
                      ),
                      rel: 'sponsored',
                      className: 'jobTestPrepLink',
                    }}
                  >
                    <span>Powered by</span>
                    <img
                      {...{
                        src: jobTestPrepImage,
                        alt: 'JobTestPrep',
                        className: 'jobTestPrepImage',
                      }}
                    />
                  </a>
                </div>
              </div>
            )}
            <div className="allQuestion">
              <div className="allQuestionList">
                {this.test.questions.map(
                  (
                    {
                      infoNode,
                      questionNode,
                      explanationNode,
                      answers,
                      id,
                      ads,
                    },
                    questionIndex,
                  ) => {
                    const totalQuestions = this.test.questions.length
                    return (
                      <div key={questionIndex}>
                        <div
                          {...{
                            className: 'questionContent',
                            key: id,
                          }}
                        >
                          <div className="questionCount">
                            {this.test.name}: question {questionIndex + 1} of{' '}
                            {totalQuestions}
                          </div>
                          <Test
                            {...{
                              info: infoNode.childMarkdownRemark.html,
                              question: questionNode.childMarkdownRemark.html,
                              explanation:
                                explanationNode.childMarkdownRemark.html,
                              answers: JSON.parse(answers),
                              selectedAnswer: this.state.showResults,
                              handleAnswer: (answerIndex) =>
                                this.handleAnswer(questionIndex, answerIndex),
                            }}
                          />
                          <div className="questionPagination">
                            <Pagination
                              {...{
                                answers: this.state.answers,
                                questions: this.test.questions,
                                handleNavigate: this.handleNavigate,
                              }}
                            />
                          </div>
                        </div>
                        <div className="practiceMore">
                          <a
                            {...{
                              className:
                                'practiceMoreBtn btn btn-orange btn-lg',
                              rel: 'sponsored',
                              href: decorateURLWithTID(
                                this.test.url,
                                this.props.location.pathname,
                                'practicemore',
                              ),
                              target: '_blank',
                            }}
                          >
                            CLICK HERE TO PRACTICE MORE TESTS
                          </a>
                        </div>
                        <div className="row">
                          <div className="content_hint" />
                        </div>
                      </div>
                    )
                  },
                )}
                {this.state.showResults && (
                  <>
                    <Results
                      {...{
                        questions: this.test.questions,
                        answers: this.state.answers,
                        passPercentage: this.test.passPercentage,
                      }}
                    />
                    <div className="practiceMore">
                      <a
                        {...{
                          className: 'practiceMoreBtn btn btn-orange btn-lg',
                          rel: 'sponsored',
                          href: decorateURLWithTID(
                            this.test.url,
                            this.props.location.pathname,
                            'practicemore',
                          ),
                          target: '_blank',
                        }}
                      >
                        CLICK HERE TO PRACTICE MORE TESTS
                      </a>
                    </div>
                  </>
                )}
              </div>
            </div>
          </Col>
          <Col style={{ minWidth: '330px' }} className="sidebar" lg={4}>
            <BuyBox
              location={this.props.location}
              testUrl={this.test.url}
              testName={this.test.name}
            />
          </Col>
        </Row>

        {pageIsScrolled && exitIntentPopupShown && (
          <ExitIntentPopup
            pathname={this.props.location.pathname}
            closePopup={() =>
              this.setState({ ...this.state, exitIntentPopupShown: false })}
            testUrl={this.test.url}
          />
        )}
      </>
    )
  }
}

const styles = `
  .questionCount {
    background: rgba(0, 0, 0, 0.03);
    padding: 12px 40px;
    font-family: 'PT Sans', sans-serif;
  }

  .questionPagination {
    background-color: rgba(0, 0, 0, 0.03);
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 3px;
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
  }
  .questionTitle h2 {
    font-family: 'PT Sans', sans-serif;
  }
  .questionPagination {
    border: none;
    border-top: 1px solid rgba(0, 0, 0, 0.125);
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    font-family: 'PT Sans', sans-serif;
  }
  .questionContent {
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 3px;
    margin: 20px 0;
  }
  .jobTestPrepLink {
    color: #000;
    display: inline-block;
    margin-bottom: 10px;
  }
  .jobTestPrepLink:hover {
    color: #000;
    text-decoration: none;
  }
  .jobTestPrepLink span {
    display: block;
  }
  .jobTestPrepImage {
    max-width: 200px !important;
  }
  .practiceMore {
    margin: 20px auto;
    text-align: center;
  }
  .practiceMoreBtn {
    background: #ed863e;
    border: 0;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.3);
    transition: all ease .3s;
  }
  .practiceMoreBtn:hover {
    background: #ff5900;
  }
  .answeredPoint {
    background: #ed863e;
  }
  .stickyTestHeaderWrapper {
    position: fixed;
    top: -150px;
    left: 0;
    right: 0;
    opacity: 0;
    z-index: 999;
    transition: all .45s ease-in;
  }
  .stickyTestHeaderWrapperIsShown {
    opacity: 1;
    top: 0;
  }
`

export const query = graphql`
  query($id: String!) {
    datoCmsTest(id: { eq: $id }) {
      id
      name
      url
      urlCanonical
      description
      introText
      group
      timeLimit
      passPercentage
      metaTitle
      metaDescription
      questions {
        id
        infoNode {
          childMarkdownRemark {
            html
          }
        }
        questionNode {
          childMarkdownRemark {
            html
          }
        }
        explanationNode {
          childMarkdownRemark {
            html
          }
        }
        answers
      }
    }
  }
`

const mapDispatch = { setHelloBarContent }

export default connect(
  null,
  mapDispatch,
)(AptitudeTest)
