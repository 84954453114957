import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { window } from 'browser-monads';
import Button from 'src/components/Button';

class Pagination extends Component {
  // TODO: проверка на корректность ответов, ставился маркер зеленый или красный на вопрос отвеченный
  isCorrect(key) {
    const questions = this.props.questions;
    const question = questions[key];
    const answers = this.props.answers;
    const answer = answers[key];
    if (answers.hasOwnProperty(key)) {
      return question.answers[answer].correct;
    }
    return null;
  }

  handleScroll = key => {
    // TODO: прокрутка, вынести в отдельную функцию
    const card = document.getElementsByClassName(`questionContent`)[key];
    const cardOffsetTop = card.offsetTop;
    if (window.innerWidth <= 767) {
      window.scrollTo({ top: cardOffsetTop - 70, behavior: 'smooth' });
    } else if (window.innerWidth >= 768 && window.innerWidth <= 991) {
      window.scrollTo({ top: cardOffsetTop - 150, behavior: 'smooth' });
    } else if (window.innerWidth >= 992) {
      window.scrollTo({ top: cardOffsetTop - 130, behavior: 'smooth' });
    }
  };

  render() {
    const { questions } = this.props;

    return (
      <div>
        <nav>
          <ul
            className="pagination flex-wrap"
            style={{ justifyContent: 'center' }}
          >
            {questions.map((question, key) => (
              <li
                {...{
                  key,
                  className: 'page-item',
                }}
              >
                <Button
                  {...{
                    className: 'page-link',
                    value: String(key + 1),
                    handleClick: () => this.handleScroll(key),
                    isCorrect: this.isCorrect(key),
                  }}
                />
              </li>
            ))}
          </ul>
        </nav>
        <div className="text-center pt-2">
          <button
            className="btn btn-success text-white btn-lg"
            style={{ zoom: 1.2 }}
            onClick={() => this.props.handleNavigate(true)}
          >
            Finish test & show result
          </button>
        </div>
      </div>
    );
  }
}

Pagination.propTypes = {
  answers: PropTypes.object.isRequired,
  questions: PropTypes.array.isRequired,
  handleNavigate: PropTypes.func.isRequired,
};

export default Pagination;
