import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './Variant.module.scss';

class Variant extends Component {
  render() {
    const { answers, isAnswered, selectedAnswer } = this.props;

    return answers.map((answer, key) => (
      <>
        <label {...{
          key,
          answer,
          className: classNames(styles.answerLabel, isAnswered && answer.correct ? styles.success : null)
        }}>
          <input {...{
            key,
            hidden: true,
            type: 'radio',
            checked: selectedAnswer === key,
            onChange: () => this.props.handleAnswer(key),
            disabled: isAnswered
          }}/>
          {isAnswered && !answer.correct ? <s>{answer.value}</s> : answer.value}
        </label>
      </>
    ))
  }
}

Variant.propTypes = {
  answers: PropTypes.array.isRequired,
  isAnswered: PropTypes.bool.isRequired,
  selectedAnswer: PropTypes.bool,
}

export default Variant
