import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Variant from '../Variant';
import styles from './Answers.module.scss';

class Answers extends Component {
  render() {
    const selectedAnswer = this.props.selectedAnswer;
    const isAnswered = selectedAnswer;

    return (
      <div className={styles.answersList}>
        <Variant {...{
          isAnswered,
          answers: this.props.answers,
          selectedAnswer: this.props.selectedAnswer,
          handleAnswer: this.props.handleAnswer,
        }}/>
        </div>
    )
  }
}

Answers.propTypes = {
  isAnswered: PropTypes.bool,
  answers: PropTypes.array.isRequired,
  selectedAnswer: PropTypes.bool,
  handleAnswer: PropTypes.func.isRequired,
}

export default Answers
