import React from 'react';
import PropTypes from 'prop-types';
import Explanation from '../Explanation';
import Answers from '../Answers';
import styles from './Test.module.scss';

export default function Test(props) {
  const selectedAnswer = props.selectedAnswer;
  const isAnswered = selectedAnswer;
  return (
    <div className={styles.questionText}>
      <div
        {...{
          className: styles.info,
          dangerouslySetInnerHTML: { __html: props.info },
        }}
      />
      <div
        {...{
          className: styles.question,
          dangerouslySetInnerHTML: { __html: props.question },
        }}
      />
      <hr />
      <Answers
        {...{
          selectedAnswer,
          answers: props.answers,
          handleAnswer: props.handleAnswer,
        }}
      />
      {isAnswered && <Explanation value={props.explanation} />}
    </div>
  );
}

Test.propTypes = {
  info: PropTypes.string.isRequired,
  question: PropTypes.string.isRequired,
  explanation: PropTypes.string.isRequired,
  answers: PropTypes.array.isRequired,
  selectedAnswer: PropTypes.bool,
  handleAnswer: PropTypes.func.isRequired,
};
