import React, { Component } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { document } from 'browser-monads'
import styles from './Results.module.scss'

class Results extends Component {
  countCorrect(questions, answers) {
    return questions.reduce((output, question, key) => {
      return answers.hasOwnProperty(key) &&
        question.answers[answers[key]].correct
        ? output + 1
        : output;
    }, 0);
  }

  render() {
    const total = this.props.questions.length;
    const correct = this.countCorrect(this.props.questions, this.props.answers);
    const persentage = (correct / total) * 100;
    const passPercentage = this.props.passPercentage;

    return (
      <div
        className={classNames(
          'result',
          styles.result,
          persentage >= passPercentage
            ? styles.resultPassed
            : styles.resultFailed
        )}
      >
        {persentage >= passPercentage ? <h2>Pass</h2> : <h2>Fail</h2>}
        <p>{`Score: ${correct}/${total} - ${Math.round(persentage)}%`}</p>
        <p>{`Pass: ${this.props.passPercentage}%`}</p>
        <p>
          The test is complete. You can go back to the questions again and find
          explanations for the questions there.
        </p>
        {persentage >= passPercentage ? null : (
          <Link
            className={classNames('btn', styles.tryAgain)}
            to={document.location.href}
          >
            Try again
          </Link>
        )}
      </div>
    );
  }
}

Results.propTypes = {
  questions: PropTypes.array.isRequired,
  answers: PropTypes.object.isRequired,
  passPercentage: PropTypes.number.isRequired,
};

export default Results;
