import React from 'react'
import styles from './styles.module.scss'
import dayjs from 'dayjs'
import decorateURLWithTID from '../../utils/decorateUrlTid'

const formatYear = (s) => s.replace('{YEAR}', dayjs().format('YYYY'))

const StickyTestHeader = ({ test, location }) => {
  return (
    <div className={styles.stickyTestHeader}>
      <div className={`container ${styles.stickyTestHeaderContainer}`}>
        <h2>
          {formatYear(test.name && test.name)}
        </h2>

        {test.url && (
          <a
            href={decorateURLWithTID(test.url,
              location ? location.pathname: '', 'stickyheader')}
            className={`stickyHeaderButton btn btn-orange ${styles.stickyTestHeaderButton}`}
            role="button"
            aria-pressed="true"
            rel="sponsored nofollow noreferrer"
            target='_blank'
          >
            Practice More
          </a>
        )}
      </div>
    </div>
  )
}

export default StickyTestHeader
