import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './Button.module.scss'

class Button extends Component {
  render() {
    const classes = ['']
    if (this.props.isCorrect !== null) {
      classes.push('bg-dark text-white')
    }

    return (
      <button
        className={classNames(styles.pageLink, classes.join(' '))}
        onClick={this.props.handleClick}>
        {this.props.value}
      </button>
    )
  }
}

Button.propTypes = {
  isCorrect: PropTypes.bool,
  value: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
}

export default Button
