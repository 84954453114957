import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './Explanation.module.scss'

class Explanation extends Component {
  render() {
    return (
      <div className={styles.questionExplanation}>
        <hr />
        <div dangerouslySetInnerHTML={{ __html: this.props.value }} />
      </div>
    )
  }
}

Explanation.propTypes = {
  value: PropTypes.string.isRequired,
}

export default Explanation
